"use client";

import type { ReactNode } from "react";
import { SWRConfig } from "swr";

import { env } from "@/env-client";

export const SwrProvider = ({ children }: { children: ReactNode }) => (
  <SWRConfig
    value={{
      fetcher: (...args: Parameters<typeof fetch>) => fetch(...args).then((res) => res.json()),
      revalidateOnFocus: env.NODE_ENV !== "development",
    }}
  >
    {children}
  </SWRConfig>
);
